/** OE上报类型 */
export var OeReportType;
(function (OeReportType) {
    /** 关键行为 */
    OeReportType[OeReportType["Action"] = 1001] = "Action";
})(OeReportType || (OeReportType = {}));
/** OE上报子类型 */
export var OeReportSubType;
(function (OeReportSubType) {
    /** 点击广告后回来经过的时长 */
    OeReportSubType[OeReportSubType["BackAfterAdClick"] = 30000] = "BackAfterAdClick";
    /** 任何一个广告的点击 */
    OeReportSubType[OeReportSubType["AdAllClick"] = 30001] = "AdAllClick";
    /** 任何一个广告的填充成功 */
    OeReportSubType[OeReportSubType["AdAllReqSuccess"] = 30002] = "AdAllReqSuccess";
    /** 任何一个广告的填充成功 */
    OeReportSubType[OeReportSubType["AdAllReqSuccess2"] = 30003] = "AdAllReqSuccess2";
    /** 非首页曝光 */
    OeReportSubType[OeReportSubType["PageShowNotMainOld"] = 30004] = "PageShowNotMainOld";
    /** 跳转快应用按钮曝光 */
    OeReportSubType[OeReportSubType["QappBtnShow"] = 30005] = "QappBtnShow";
    /** 跳转快应用按钮点击 */
    OeReportSubType[OeReportSubType["QappBtnClick"] = 30006] = "QappBtnClick";
    /** 浏览器刷新 */
    OeReportSubType[OeReportSubType["Refresh"] = 30007] = "Refresh";
    /** 首次互动页、首次首页广告点击 */
    OeReportSubType[OeReportSubType["SpecialClick"] = 30008] = "SpecialClick";
    /** 广告请求 */
    OeReportSubType[OeReportSubType["AdReq"] = 31000] = "AdReq";
    /** 广告展示 */
    OeReportSubType[OeReportSubType["AdShow"] = 31001] = "AdShow";
    /** 广告点击 */
    OeReportSubType[OeReportSubType["AdClick"] = 31002] = "AdClick";
    /** 广告关闭 */
    OeReportSubType[OeReportSubType["AdClose"] = 31003] = "AdClose";
    /** 广告请求成功 */
    OeReportSubType[OeReportSubType["AdReqSuccess"] = 31004] = "AdReqSuccess";
    /** 广告请求失败 */
    OeReportSubType[OeReportSubType["AdReqFail"] = 31005] = "AdReqFail";
    /** 广告销毁 */
    OeReportSubType[OeReportSubType["AdDestroy"] = 31007] = "AdDestroy";
    /** 左上角Back点击 */
    OeReportSubType[OeReportSubType["TopLeftBack"] = 31012] = "TopLeftBack";
    /** 返回弹窗点击确认 */
    OeReportSubType[OeReportSubType["BackDialogConfirm"] = 31013] = "BackDialogConfirm";
    /** Tab手动点击上报 */
    OeReportSubType[OeReportSubType["TabClick"] = 31014] = "TabClick";
    /** 首页曝光 */
    OeReportSubType[OeReportSubType["HomeShow"] = 31015] = "HomeShow";
    /** 日启频次 */
    OeReportSubType[OeReportSubType["DailyLaunch"] = 31016] = "DailyLaunch";
    /** 单启前台停留时长 */
    OeReportSubType[OeReportSubType["Stay"] = 31017] = "Stay";
    /** 下载 */
    OeReportSubType[OeReportSubType["Download"] = 31018] = "Download";
    /** 收藏 */
    OeReportSubType[OeReportSubType["Collect"] = 31019] = "Collect";
    /** 点赞 */
    OeReportSubType[OeReportSubType["Like"] = 31020] = "Like";
    /** 单启页面深度（默认页面前台超1s才算一个） */
    OeReportSubType[OeReportSubType["PageDeep"] = 31021] = "PageDeep";
    /** 点击下一个 */
    OeReportSubType[OeReportSubType["NextClick"] = 31022] = "NextClick";
    /** 点击换一个 */
    OeReportSubType[OeReportSubType["OtherClick"] = 31023] = "OtherClick";
    /** 系统返回键监听（有就报，没有不报） */
    OeReportSubType[OeReportSubType["BackPress"] = 31025] = "BackPress";
    /** 应用Hide */
    OeReportSubType[OeReportSubType["AppHide"] = 31027] = "AppHide";
    /** 非法点击(action_value =页面名称 action_value2 =点击区域名称 app_x, app_y) */
    OeReportSubType[OeReportSubType["IllegalClick"] = 31028] = "IllegalClick";
    /** 功能按钮点击(action_value =页面名称 action_value2 =点击名称) */
    OeReportSubType[OeReportSubType["BtnClick"] = 31029] = "BtnClick";
    /** 页面滑动次数上报(action_value =页面名称 action_value2 =页面ID) */
    OeReportSubType[OeReportSubType["PageTouchMoved"] = 31030] = "PageTouchMoved";
    /** 全页面曝光 */
    OeReportSubType[OeReportSubType["PageShow"] = 31031] = "PageShow";
    /** 应用到前台 */
    OeReportSubType[OeReportSubType["AppShow"] = 31032] = "AppShow";
    /** 启动上报(action_value =启动次数,>1都是热启) */
    OeReportSubType[OeReportSubType["AppOeLaunch"] = 31033] = "AppOeLaunch";
    /** 非首页曝光 */
    OeReportSubType[OeReportSubType["PageShowNotMain"] = 31035] = "PageShowNotMain";
    /** 行为收集（仅做分析数据用） */
    OeReportSubType[OeReportSubType["ActionCollect"] = 31036] = "ActionCollect";
    /** 付费行为 */
    OeReportSubType[OeReportSubType["PayAction"] = 31037] = "PayAction";
    /** 互动页非关键区域点击上报 */
    OeReportSubType[OeReportSubType["NonCriticalAreaClick"] = 31038] = "NonCriticalAreaClick";
})(OeReportSubType || (OeReportSubType = {}));
/** 上报EVENT类型 */
export var ReportEvent;
(function (ReportEvent) {
    ReportEvent["SDK"] = "HaaApp";
    ReportEvent["AppLaunch"] = "AppLaunch";
    ReportEvent["QuickApp"] = "QuickApp";
    ReportEvent["EnterPage"] = "EnterPage";
    ReportEvent["ExitPage"] = "ExitPage";
    ReportEvent["Exception"] = "Exception";
    ReportEvent["ClickAction"] = "ClickAction";
    ReportEvent["Helper"] = "Helper";
    ReportEvent["Strategy"] = "AD_CONFIG";
    ReportEvent["Ad"] = "AdAction";
})(ReportEvent || (ReportEvent = {}));
