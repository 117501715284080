import CryptoJS from 'crypto-js';
const key = CryptoJS.enc.Utf8.parse('47FC4124CF94E079');
const iv = CryptoJS.enc.Utf8.parse('ovOh2xYoRdfATob6');
// aes加密方法
export const AesEncode = function (word) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return encodeURIComponent(encryptedBase64Data);
};
// aes解密方法
export const AesDecode = (word) => {
    try {
        word = decodeURIComponent(word);
        let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        // console.error(decrypt);
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
    catch (e) {
        // console.error(e);
        return '';
    }
};
