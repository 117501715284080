import { onBeforeMount, onMounted, onBeforeUnmount, watch, reactive } from 'vue';
import { OeReportType, OeReportSubType, ReportEvent } from './enum';
import { useOeReport } from './useOeReport';
import { useReport } from './useReport';
import { useRouter } from './useRouter';
import { getParamsFromUrl } from './utils';
import { useStrategy } from './useStrategy';
import { _first_inter_show } from './useDirective';
const { oeReport } = useOeReport();
const { report } = useReport();
const { router } = useRouter();
const { strategy, adControl } = useStrategy();
/** 是否展示广告 */
const NO_AD = false;
/** 广告状态 */
const AdStage = {
    Req: 'AdReq',
    Close: 'AdClose',
    Click: 'AdClick',
    Init: 'AdInit',
};
/** 广告填充 */
const AdReqState = {
    Success: 'success',
    Fail: 'fail'
};
/** 广告id */
let _adId = 0;
/** 广告已经请求次数 */
let AD_REQ_NUMBER = 0;
/** 根据spaceKey获取广告位id */
const getSpaceInfoByKey = (spaceKey) => {
    if (strategy.is_ad_shield == 1)
        return [];
    if (strategy.ad_req_max >= 0 && strategy.ad_req_max < AD_REQ_NUMBER) {
        adControl.ad_req_limit = true;
        return [];
    }
    let spaceInfo = strategy.h5_ad_space_config[spaceKey];
    if (spaceInfo) {
        AD_REQ_NUMBER += 1;
        return spaceInfo.slice();
    }
    else {
        return [];
    }
};
/** 根据广告位id获取spaceKey */
export const getSpaceKeyById = (id) => {
    let meta = router.routerInstance.currentRoute.value.meta;
    let spaceKey = '';
    if (meta && meta.spaceKeys && meta.spaceKeys.length) {
        meta.spaceKeys.forEach((key) => {
            if (strategy.h5_ad_space_config[key]) {
                let spaceInfo = strategy.h5_ad_space_config[key].find(item => item.space_id == id);
                if (spaceInfo)
                    spaceKey = key;
            }
        });
    }
    else {
        Object.keys(strategy.h5_ad_space_config).forEach((key) => {
            let spaceInfo = strategy.h5_ad_space_config[key].find(item => item.space_id == id);
            if (spaceInfo)
                spaceKey = key;
        });
    }
    return spaceKey;
};
/** 通用打点 */
const handleReport = (spaceKey, spaceInfo, adstage, adId, pageName, msg, msg2) => {
    let params = {
        EVENT: ReportEvent.Ad,
        PageName: pageName,
        SpaceKey: spaceKey,
        SpaceId: spaceInfo === null || spaceInfo === void 0 ? void 0 : spaceInfo.space_id,
        AdComId: adId,
        AdType: spaceInfo === null || spaceInfo === void 0 ? void 0 : spaceInfo.space_type,
        AdStage: adstage,
    };
    if (msg)
        params.MSG = msg;
    if (msg2)
        params.MSG2 = msg2;
    report(params);
};
class adHelper {
    constructor() {
        this._latestClickedSpaceId = null;
        this._lastestClickedSpaceKey = null;
        this._latestClickedTuIndex = null;
        this._latestClickedTime = 0;
    }
    clear() {
        this._latestClickedSpaceId = null;
        this._lastestClickedSpaceKey = null;
        this._latestClickedTuIndex = null;
        this._latestClickedTime = 0;
    }
    click({ spaceId, tu_index }, spaceKey) {
        this._latestClickedSpaceId = spaceId;
        this._lastestClickedSpaceKey = spaceKey;
        this._latestClickedTuIndex = tu_index;
        this._latestClickedTime = new Date().getTime();
    }
    pageVisible() {
        if (!this._latestClickedSpaceId || !this._lastestClickedSpaceKey || !this._latestClickedTuIndex || this._latestClickedTime == 0)
            return;
        // 上报
        var passTime = new Date().getTime() - this._latestClickedTime + '';
        // console.error('距离上次点击停留了'+passTime+'毫秒', '广告ID = ' + _latestClickedSpaceId, 'tu_idex = ' + _latestClickedTuIndex);
        oeReport(OeReportType.Action, OeReportSubType.BackAfterAdClick, this._latestClickedSpaceId, this._lastestClickedSpaceKey, passTime, undefined, router.routerInstance.currentRoute.value.name);
        this.clear();
    }
    pageChanged() {
        this.clear();
    }
}
export const _adHelper = new adHelper();
/** 插屏广告 */
export function useWapHook(spaceKey) {
    /** 广告id */
    let adId = ++_adId;
    /** 广告信息组 */
    let spaceInfos = getSpaceInfoByKey(spaceKey);
    /** 广告信息 */
    let spaceInfo = reactive({ data: spaceInfos.shift() });
    /** 初始化广告的页面名 */
    let pageName = router.routerInstance.currentRoute.value.name;
    /** 广告初始化打点 */
    const init = () => {
        var _a;
        handleReport(spaceKey, spaceInfo.data, AdStage.Init, adId, pageName);
        oeReport(OeReportType.Action, OeReportSubType.AdReq, (_a = spaceInfo.data) === null || _a === void 0 ? void 0 : _a.space_id, spaceKey, undefined, adId, pageName);
    };
    /** 如果组件初始化的时候，策略还没成功，需要等策略返回再初始化 */
    if (strategy.status == 0) {
        watch(() => strategy.status, () => {
            spaceInfos = getSpaceInfoByKey(spaceKey);
            spaceInfo.data = spaceInfos.shift();
            if (spaceInfo.data && spaceInfo.data.space_id)
                init();
        });
    }
    /** 广告关闭回调 */
    const handleAdClose = ({ spaceId, tu_index }) => {
        var _a, _b;
        if (!spaceId || !tu_index)
            return;
        if (spaceId !== ((_a = spaceInfo.data) === null || _a === void 0 ? void 0 : _a.space_id))
            return;
        let curPage = router.routerInstance.currentRoute.value.name;
        if (pageName != curPage)
            return;
        handleReport(spaceKey, spaceInfo.data, AdStage.Close, adId, pageName);
        oeReport(OeReportType.Action, OeReportSubType.AdClose, (_b = spaceInfo.data) === null || _b === void 0 ? void 0 : _b.space_id, spaceKey, undefined, adId, pageName);
    };
    /** 广告失败回调 */
    const handleAdError = ({ spaceId, tu_index, errorInfo }) => {
        var _a, _b;
        if (!spaceId || !tu_index)
            return;
        if (spaceId !== ((_a = spaceInfo.data) === null || _a === void 0 ? void 0 : _a.space_id))
            return;
        let curPage = router.routerInstance.currentRoute.value.name;
        if (pageName != curPage)
            return;
        handleReport(spaceKey, spaceInfo.data, AdStage.Req, adId, pageName, AdReqState.Fail, errorInfo);
        oeReport(OeReportType.Action, OeReportSubType.AdReqFail, (_b = spaceInfo.data) === null || _b === void 0 ? void 0 : _b.space_id, spaceKey, undefined, adId, pageName);
        /** 如果广告失败了，该广告位还有其他广告id，继续请求 */
        let info = spaceInfos.shift();
        if (info) {
            spaceInfo.data = info;
            init();
        }
    };
    /** 广告点击回调 */
    const handleAdClick = ({ spaceId, tu_index }) => {
        var _a, _b;
        if (!spaceId || !tu_index)
            return;
        if (spaceId !== ((_a = spaceInfo.data) === null || _a === void 0 ? void 0 : _a.space_id))
            return;
        let curPage = router.routerInstance.currentRoute.value.name;
        if (pageName != curPage)
            return;
        _adHelper.click({ spaceId, tu_index }, spaceKey);
        handleReport(spaceKey, spaceInfo.data, AdStage.Click, adId, pageName);
        oeReport(OeReportType.Action, OeReportSubType.AdClick, (_b = spaceInfo.data) === null || _b === void 0 ? void 0 : _b.space_id, spaceKey, undefined, adId, pageName);
        let deepCount = sessionStorage.getItem('HAA_PAGE_DEEP');
        let countNumber = deepCount ? +deepCount : 1;
        if (countNumber <= 2) {
            oeReport(OeReportType.Action, OeReportSubType.SpecialClick, spaceId, spaceKey, undefined, adId, pageName);
        }
    };
    /** 广告组件初始化 */
    onBeforeMount(() => {
        if (NO_AD)
            return;
        if (strategy.status != 0 && spaceInfo && spaceInfo.data && spaceInfo.data.space_id)
            init();
    });
    /** 广告组件渲染 */
    onMounted(() => {
    });
    /** 广告组件销毁 */
    onBeforeUnmount(() => {
        var _a;
        oeReport(OeReportType.Action, OeReportSubType.AdDestroy, (_a = spaceInfo.data) === null || _a === void 0 ? void 0 : _a.space_id, spaceKey, undefined, adId, pageName);
    });
    return { adId, spaceInfo: spaceInfo, init, handleAdClose, handleAdError, handleAdClick };
}
/** 辅助广告填充打点 */
const _map = {};
const _timer = {};
const _timer2 = {};
export function addTimer(src) {
    try {
        if (!src)
            return;
        _map[src] = true;
        const query = getParamsFromUrl(src);
        const spaceId = query.di;
        const tu_index = spaceId + '_' + query.dri;
        let timerId = null;
        timerId = setTimeout(() => {
            oeReport(OeReportType.Action, OeReportSubType.AdAllReqSuccess, spaceId, getSpaceKeyById(spaceId), undefined, router.routerInstance.currentRoute.value.name);
            _timer[src] = undefined;
        }, 500);
        _timer[src] = timerId;
        timerId = setTimeout(() => {
            // 记录一下首页插屏的展示情况
            let spaceKey = getSpaceKeyById(spaceId);
            if (_first_inter_show.spaceKey && _first_inter_show.spaceKey == spaceKey) {
                _first_inter_show.shown = true;
                sessionStorage.setItem('HAA_FIRST_INTER', 'shown');
            }
            oeReport(OeReportType.Action, OeReportSubType.AdAllReqSuccess2, spaceId, getSpaceKeyById(spaceId), undefined, router.routerInstance.currentRoute.value.name);
            oeReport(OeReportType.Action, OeReportSubType.AdShow, spaceId, getSpaceKeyById(spaceId), undefined, router.routerInstance.currentRoute.value.name);
            _timer2[src] = undefined;
        }, 1000);
        _timer2[src] = timerId;
    }
    catch (e) {
        console.log(e);
    }
}
export function removeTimer(src) {
    try {
        if (!src)
            return;
        const exit = _map[src];
        _map[src] = false;
        if (exit && _timer[src]) {
            clearTimeout(_timer[src]);
        }
        if (exit && _timer2[src]) {
            clearTimeout(_timer2[src]);
        }
        return exit;
    }
    catch (e) {
        console.log(e);
    }
}
export function isWapNode(node) {
    if (!node)
        return;
    return node.nodeName && node.nodeName.toLowerCase() == 'iframe' && node.src && node.src.indexOf('baidu') > -1;
}
export function isWapNodeForEach(node) {
    try {
        if (!node)
            return;
        if (!node.childNodes || !node.childNodes.length)
            return;
        for (let i = 0; i < node.childNodes.length; i++) {
            const c = node.childNodes[i];
            if (c.nodeName.toLowerCase() == 'iframe' && c.firstChild.indexOf('baidu') > -1) {
                return c;
            }
            else {
                let next = isWapNodeForEach(c);
                if (next)
                    return next;
            }
        }
    }
    catch (e) {
    }
}
