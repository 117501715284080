import { OeReportSubType, OeReportType } from './helper/enum';
import { useUser } from './helper/useUser';
import { useQuery } from './helper/useQuery';
import { useDevice } from './helper/useDevice';
import { useOeReport } from './helper/useOeReport';
import { useReport } from './helper/useReport';
import { useStrategy } from './helper/useStrategy';
import { useRouter } from './helper/useRouter';
import { useLifeCycle } from './helper/useLifeCycle';
import { useVisible } from './helper/useVisible';
import { useDirective, _first_inter_show } from './helper/useDirective';
import { useWapHook, _adHelper, getSpaceKeyById, addTimer, removeTimer, isWapNode, isWapNodeForEach } from './helper/useWap';
import { getParamsFromUrl } from './helper/utils';
import bus from './bus';
const { userInfo } = useUser();
const { initQuery } = useQuery();
const { defaultStrategy, getStrategy } = useStrategy();
const { reportLaunch } = useReport();
const { oeReport, startUsingTimer, stopUsingTimer, dailyLaunchReport, _calculatedPageCount } = useOeReport();
const { setRouter } = useRouter();
const { visible } = useVisible();
const { setGlobalDirective } = useDirective();
const SESSION_AD_CLICK = 'HAA_AD_CLICK_SESSION';
let def = {
    util: () => {
        return { useDevice, useOeReport, useQuery, useReport, useStrategy, useUser, useLifeCycle, useDirective, useWapHook };
    },
    init(cfg) {
        /** 如果配置了首页插屏的spaceKey，记录一下 */
        if (cfg.firstInterSpaceKey)
            _first_inter_show.spaceKey = cfg.firstInterSpaceKey;
        /** 如果配置了页面深度初始值的话，赋值 */
        if (cfg.deepStart)
            _calculatedPageCount.count = cfg.deepStart - 1;
        /** 设置通用自定义指令 */
        setGlobalDirective(cfg.app);
        /** 保存路由实例，设置路由拦截，进行页面打点上报 */
        setRouter(cfg.router, cfg.home);
        /** 保存广告位配置 */
        defaultStrategy.h5_ad_space_config = cfg.spaceMap;
        /** 判断是否第一次进入，保存query参数 */
        if (userInfo.isRefresh) {
            getStrategy();
            // 刷新后，无法立即获取到router，设个延迟
            setTimeout(() => {
                let pageName = cfg.router.currentRoute.value.name;
                oeReport(OeReportType.Action, OeReportSubType.Refresh, pageName);
            }, 1000);
        }
        else {
            initQuery(cfg.queryMap);
            getStrategy();
            /** 上报启动 */
            dailyLaunchReport();
            reportLaunch();
            // oeReport(OeReportType.Action, OeReportSubType.AppOeLaunch);
        }
        /** 初始化时，判断是否有缓存的用户点击广告的信息，如果有，上报广告页停留时长 */
        try {
            let clickInfoStr = sessionStorage.getItem(SESSION_AD_CLICK);
            if (clickInfoStr) {
                let clickInfo = JSON.parse(clickInfoStr);
                let { spaceId, spaceKey, timestamp } = clickInfo;
                let now = new Date().getTime();
                let passTime = now - timestamp + '';
                oeReport(OeReportType.Action, OeReportSubType.BackAfterAdClick, spaceId, spaceKey, passTime, undefined, undefined);
                sessionStorage.setItem(SESSION_AD_CLICK, '');
            }
        }
        catch (error) {
            // 解析缓存数据失败
        }
        /** 开启停留时长定时器，监听前后台切换 */
        startUsingTimer();
        let displayTime = new Date().getTime();
        window.addEventListener('visibilitychange', () => {
            let isHidden = window.document.hidden;
            if (isHidden) {
                visible.value = false;
                displayTime = new Date().getTime();
                stopUsingTimer();
            }
            else {
                visible.value = true;
                _adHelper.pageVisible();
                oeReport(OeReportType.Action, OeReportSubType.AppShow, undefined, undefined, (new Date().getTime() - displayTime) + '');
                startUsingTimer();
            }
        });
        /** 监听window失去焦点，以此判断广告点击 */
        window.addEventListener('blur', () => {
            let activeElement = document.activeElement;
            if (!activeElement)
                return;
            if (activeElement.src && activeElement.src.indexOf('baidu') > -1) {
                const query = getParamsFromUrl(activeElement.src);
                const spaceId = query.di;
                const tu_index = query.di + '_' + query.dri;
                // 缓存用户点击的广告信息，再次初始化的时候，上报广告页停留的时长
                let clickInfo = {
                    spaceId,
                    spaceKey: getSpaceKeyById(spaceId),
                    timestamp: new Date().getTime(),
                };
                sessionStorage.setItem(SESSION_AD_CLICK, JSON.stringify(clickInfo));
                oeReport(OeReportType.Action, OeReportSubType.AdAllClick, spaceId, getSpaceKeyById(spaceId), undefined, cfg.router.currentRoute.value.name);
                if (!spaceId || !tu_index) {
                    oeReport(OeReportType.Action, OeReportSubType.AdClick, spaceId, undefined, undefined, undefined, cfg.router.currentRoute.value.name);
                    let deepCount = sessionStorage.getItem('HAA_PAGE_DEEP');
                    let countNumber = deepCount ? +deepCount : 1;
                    if (countNumber <= 2) {
                        oeReport(OeReportType.Action, OeReportSubType.SpecialClick, spaceId, undefined, undefined, undefined, cfg.router.currentRoute.value.name);
                    }
                }
                bus.emit('adclick', { spaceId, tu_index });
                setTimeout(() => {
                    window.focus();
                }, 0);
            }
        });
        (window.slotbydup = window.slotbydup || []).push({
            closeAd: function (tu_index) {
                if (!tu_index)
                    return;
                const spaceId = tu_index.split('_')[0];
                bus.emit('adclose', { spaceId, tu_index });
            }
        });
        (window.slotbydup = window.slotbydup || []).push({
            noAd: function (tu_index, noadInfo) {
                if (!tu_index)
                    return;
                const spaceId = tu_index.split('_')[0];
                bus.emit('aderror', { spaceId, tu_index, errorInfo: JSON.stringify(noadInfo) });
            }
        });
        /** 监听页面dom元素变化，以此判断广告填充 */
        const adGlobalObserver = new MutationObserver(function (mutationRecords) {
            if (mutationRecords && mutationRecords.length && mutationRecords.length >= 20)
                return;
            mutationRecords.forEach(v1 => {
                v1.removedNodes.forEach(v3 => {
                    const wapNode = isWapNodeForEach(v3);
                    if (wapNode)
                        removeTimer(wapNode.src);
                });
                v1.addedNodes.forEach(v2 => {
                    //@ts-ignore
                    if (isWapNode(v2))
                        addTimer(v2.src);
                });
            });
        });
        adGlobalObserver && adGlobalObserver.observe(document.body, {
            childList: true,
            subtree: true,
        });
    },
};
export default def;
export const Haa = def;
export { OeReportType, OeReportSubType };
