/** 获取随机数量字符串 */
export const randomString = (len) => {
    len = len || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678", a = t.length, n = "";
    for (let i = 0; i < len; i++) {
        let index = Math.floor(Math.random() * a);
        let str = t.substr(index, 1);
        n += str;
    }
    return n;
};
/** 根据url获取参数 */
export const getParamsFromUrl = (url) => {
    let paramStr = url.split('?')[1];
    if (!paramStr)
        return {};
    if (paramStr.indexOf('#/') > -1)
        paramStr = paramStr.split('#/')[0];
    let kavs = paramStr.split('&');
    let result = {};
    kavs.forEach(item => {
        let kv = item.split('=');
        result[kv[0]] = kv[1];
    });
    return result;
};
export const getUrlFromParams = (params) => {
    let result = '?';
    Object.keys(params).forEach(key => {
        result += `${key}=${params[key]}&`;
    });
    return result.slice(0, -1);
};
