const MobileDetect = require('mobile-detect');
const _BRANDS_OPPO = ['oppo', 'realme', 'oneplus'];
const _BRANDS_VIVO = ['vivo', 'iqoo'];
const _BRANDS_MEIZU = ['meizu'];
const _BRANDS_XIAOMI = ['xiaomi', 'redmi'];
const _BRANDS_HUAWEI = ['huawei', 'honor'];
const getNormalizedBrandName = function () {
    var _a, _b;
    const str = (_b = (_a = window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.toLowerCase();
    if (_BRANDS_OPPO.some(item => (str === null || str === void 0 ? void 0 : str.indexOf(item)) != -1)) {
        return 'oppo';
    }
    if (_BRANDS_VIVO.some(item => (str === null || str === void 0 ? void 0 : str.indexOf(item)) != -1)) {
        return 'vivo';
    }
    if (_BRANDS_MEIZU.some(item => (str === null || str === void 0 ? void 0 : str.indexOf(item)) != -1)) {
        return 'meizu';
    }
    if (_BRANDS_XIAOMI.some(item => (str === null || str === void 0 ? void 0 : str.indexOf(item)) != -1)) {
        return 'xiaomi';
    }
    if (_BRANDS_HUAWEI.some(item => (str === null || str === void 0 ? void 0 : str.indexOf(item)) != -1)) {
        return 'huawei';
    }
    return 'unknown';
};
const getNetworkType = () => {
    var ua = window.navigator.userAgent;
    var temp = ua.match(/NetType\/\w+/);
    var networkStr = temp && temp.length > 0 ? temp[0] : 'NetType/other';
    networkStr = networkStr.toLowerCase().replace('nettype/', '');
    let networkType = '';
    switch (networkStr) {
        case 'wifi':
            networkType = 'wifi';
            break;
        case '4g':
            networkType = '4g';
            break;
        case '3g':
            networkType = '3g';
            break;
        case '3gnet':
            networkType = '3g';
            break;
        case '2g':
            networkType = '2g';
            break;
        default:
            networkType = 'other';
    }
    return networkType;
};
const md = new MobileDetect(window.navigator.userAgent);
const deviceInfo = {
    AppName: APP_NAME,
    Model: md.mobile(),
    OSType: md.os(),
    DeviceType: md.mobile() == null ? 'pc' : 'phone',
    OsVersion: md.os() == 'IOS' ? md.version('iPhone') : md.os() == 'AndroidOS' ? md.version('Android') : '',
    Brand: getNormalizedBrandName(),
    netType: getNetworkType(),
};
export function useDevice() {
    return { deviceInfo };
}
