/** 星座列表 */
export const XZ_LIST: XzInfo[] = [
    {
        name: '白羊座',
        time: '3.21~4.19',
        bgcolor: '#FFE4E4',
        btncolor: '#FF9595',
        avatar: require("@/assets/images/xz/avatar/avatar-baiy.png"),
        icon: require("@/assets/images/xz/icon/icon-baiy.png"),
        img: require("@/assets/images/xz/btn/btn-baiy.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-baiy.png",
    },
    {
        name: '金牛座',
        time: '4.20~5.20',
        bgcolor: '#F5E5E3',
        btncolor: '#C9766E',
        avatar: require("@/assets/images/xz/avatar/avatar-jinn.png"),
        icon: require("@/assets/images/xz/icon/icon-jinn.png"),
        img: require("@/assets/images/xz/btn/btn-jinn.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-jinn.png",
    },
    {
        name: '双子座',
        time: '5.21~6.19',
        bgcolor: '#D7F1F3',
        btncolor: '#6ADBE7',
        avatar: require("@/assets/images/xz/avatar/avatar-shuangz.png"),
        icon: require("@/assets/images/xz/icon/icon-shuangz.png"),
        img: require("@/assets/images/xz/btn/btn-shuangz.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-shuangz.png",
    },
    {
        name: '巨蟹座',
        time: '6.22~7.22',
        bgcolor: '#FFD7D7',
        btncolor: '#FF7375',
        avatar: require("@/assets/images/xz/avatar/avatar-jux.png"),
        icon: require("@/assets/images/xz/icon/icon-jux.png"),
        img: require("@/assets/images/xz/btn/btn-jux.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-jux.png",
    },
    {
        name: '狮子座',
        time: '7.23~8.22',
        bgcolor: '#FADFC6',
        btncolor: '#FF9A3D',
        avatar: require("@/assets/images/xz/avatar/avatar-shiz.png"),
        icon: require("@/assets/images/xz/icon/icon-shiz.png"),
        img: require("@/assets/images/xz/btn/btn-shiz.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-shiz.png",
    },
    {
        name: '处女座',
        time: '8.23~9.22',
        bgcolor: '#F5D8EB',
        btncolor: '#FF92DA',
        avatar: require("@/assets/images/xz/avatar/avatar-chun.png"),
        icon: require("@/assets/images/xz/icon/icon-chun.png"),
        img: require("@/assets/images/xz/btn/btn-chun.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-chun.png",
    },
    {
        name: '天秤座',
        time: '9.23~10.23',
        bgcolor: '#F9D4D7',
        btncolor: '#E26871',
        avatar: require("@/assets/images/xz/avatar/avatar-tianp.png"),
        icon: require("@/assets/images/xz/icon/icon-tianp.png"),
        img: require("@/assets/images/xz/btn/btn-tianp.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-tianp.png",
    },
    {
        name: '天蝎座',
        time: '10.24~11.22',
        bgcolor: '#EFD7F4',
        btncolor: '#D16BEA',
        avatar: require("@/assets/images/xz/avatar/avatar-tianx.png"),
        icon: require("@/assets/images/xz/icon/icon-tianx.png"),
        img: require("@/assets/images/xz/btn/btn-tianx.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-tianx.png",
    },
    {
        name: '射手座',
        time: '11.23~12.21',
        bgcolor: '#DEEFF8',
        btncolor: '#76CFFF',
        avatar: require("@/assets/images/xz/avatar/avatar-shes.png"),
        icon: require("@/assets/images/xz/icon/icon-shes.png"),
        img: require("@/assets/images/xz/btn/btn-shes.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-shes.png",
    },
    {
        name: '摩羯座',
        time: '12.22~1.19',
        bgcolor: '#F7E9D5',
        btncolor: '#FFCB7C',
        avatar: require("@/assets/images/xz/avatar/avatar-moj.png"),
        icon: require("@/assets/images/xz/icon/icon-moj.png"),
        img: require("@/assets/images/xz/btn/btn-moj.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-moj.png",
    },
    {
        name: '双鱼座',
        time: '2.19~3.20',
        bgcolor: '#D9F4EA',
        btncolor: '#57DCA9',
        avatar: require("@/assets/images/xz/avatar/avatar-shuangy.png"),
        icon: require("@/assets/images/xz/icon/icon-shuangy.png"),
        img: require("@/assets/images/xz/btn/btn-shuangy.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-shuangy.png",
    },
    {
        name: '水瓶座',
        time: '1.20~2.18',
        bgcolor: '#E1E0FA',
        btncolor: '#8C84F2',
        avatar: require("@/assets/images/xz/avatar/avatar-shuip.png"),
        icon: require("@/assets/images/xz/icon/icon-shuip.png"),
        img: require("@/assets/images/xz/btn/btn-shuip.png"),
        watermark: "https://res-xz.baizlink.top/zodiac_app/xzcck/watermark-shuip.png",
    },
];

/** 运势分类 */
export const FORTUNE_TYPE: FortuneType[] = [
    { label: '健康运势', text: '健康', type: 'health', icon: require('@/assets/images/xz/ys/icon_health.png'), color: '#7ED8FF', bgColor: '#DFF5FF', value: '', content: '' },
    { label: '爱情运势', text: '爱情', type: 'love', icon: require('@/assets/images/xz/ys/icon_love.png'), color: '#FF8DC4', bgColor: '#FFEFFB', value :'', content: '' },
    { label: '财运运势', text: '财运', type: 'money', icon: require('@/assets/images/xz/ys/icon_money.png'), color: '#FFCA63', bgColor: '#FFF5E3', value: '', content: '' },
    { label: '事业运势', text: '工作', type: 'work', icon: require('@/assets/images/xz/ys/icon_work.png'), color: '#B196FF', bgColor: '#F0EBFF', value: '', content: '' },
];

const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6909389', space_js: '//xzfp.zoosalos365.com/source/w/rq/openjs/z-xzj/static/tz.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6909393', space_js: '//xzfp.zoosalos365.com/source/rm/lus/production/ue/ue.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6909390', space_js: '//xzfp.zoosalos365.com/site/o/production/jirp/source/r/openjs/br_p.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6909391', space_js: '//xzfp.zoosalos365.com/site/pkjsq/sc/static/s/resource/t.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6909392', space_js: '//xzfp.zoosalos365.com/site/q/common/lk/resource/tr_tdt/source/a.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6909393', space_js: '//xzfp.zoosalos365.com/source/rm/lus/production/ue/ue.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Guide_banner: BANNER,
    Home_inter: INTER,
    Home_native_inter: NATIVE_3,
    Home_banner: BANNER,
    Home_top: NATIVE_1,
    Home_center: NATIVE_2,
    Analysis_top: NATIVE_1,
    Analysis_center: NATIVE_2,
    Topic_top: NATIVE_1,
    FortuneDetail_day_top: NATIVE_1,
    FortuneDetail_week_top: NATIVE_2,
    FortuneDetail_month_top: NATIVE_3,
    AnalysisDetail_analysis_top: NATIVE_1,
    AnalysisDetail_analysis_center: NATIVE_3,
    AnalysisDetail_fortune_top: NATIVE_2,
    AnswerDetail_inter: INTER,
    AnswerDetail_banner: BANNER,
    TopicList_top: NATIVE_1,
    TopicDetail_banner: BANNER,
    TopicResult_native: NATIVE_1,
    TopicResult_banner: BANNER,
    Detail_inter: INTER,
};
